/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=59b7fa98&scoped=true"
import script from "./_id.vue?vue&type=script&lang=js"
export * from "./_id.vue?vue&type=script&lang=js"
import style0 from "./_id.vue?vue&type=style&index=0&id=59b7fa98&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59b7fa98",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loading: require('/code/src/components/Loading.vue').default,ShippingStatusTag: require('/code/src/components/widgets/ShippingStatusTag.vue').default,FloatingTooltip: require('/code/src/components/tooltip/FloatingTooltip.vue').default,AlertArea: require('/code/src/components/AlertArea.vue').default,VerticalTable: require('/code/src/components/table/VerticalTable.vue').default,SectionSubTitle: require('/code/src/components/text/SectionSubTitle.vue').default,LinkText: require('/code/src/components/text/LinkText.vue').default,AddressLabel: require('/code/src/components/widgets/AddressLabel.vue').default,AddressAlert: require('/code/src/components/widgets/AddressAlert.vue').default,TouchSummary: require('/code/src/components/widgets/TouchSummary.vue').default,TouchTextViewer: require('/code/src/components/widgets/TouchTextViewer.vue').default,TextButton: require('/code/src/components/button/TextButton.vue').default,Input: require('/code/src/components/input/Input.vue').default,GiftPageEditor: require('/code/src/components/widgets/GiftPageEditor.vue').default,ContentArea: require('/code/src/components/ContentArea.vue').default,FrameText: require('/code/src/components/text/FrameText.vue').default,CustomDialog: require('/code/src/components/dialog/CustomDialog.vue').default})
