import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm$orderResponse, _vm$orderResponse2, _vm$orderResponse$ass, _vm$orderResponse3, _vm$orderResponse3$as, _vm$orderResponse$ass2, _vm$orderResponse4, _vm$orderResponse4$as, _vm$orderResponse5, _vm$orderResponse5$as, _vm$user, _vm$orderResponse6, _vm$orderResponse7, _vm$orderResponse7$cr, _vm$user2, _vm$orderResponse8, _vm$orderResponse9, _vm$productCustoms$te, _vm$orderResponse11, _vm$orderResponse11$c, _vm$orderResponse12, _vm$orderResponse13, _vm$orderResponse14, _vm$orderResponse15;
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_c('Loading', {
    attrs: {
      "value": _vm.processing,
      "modal": ""
    }
  }), _vm._v(" "), _c(VToolbar, {
    staticClass: "elevation-0 transparent media-toolbar"
  }, [_c(VToolbarTitle, [_c('h5', [_vm._v(_vm._s(_vm.$t('orderDetail')))])]), _vm._v(" "), _vm.statusDisplay ? _c('ShippingStatusTag', {
    staticClass: "mx-4",
    attrs: {
      "text": _vm.statusDisplay,
      "status": (_vm$orderResponse = _vm.orderResponse) === null || _vm$orderResponse === void 0 ? void 0 : _vm$orderResponse.status
    }
  }) : _vm._e(), _vm._v(" "), _c(VSpacer), _vm._v(" "), _vm.canEditOrder ? _c('button', {
    staticClass: "tw-btn tw-btn-outline-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.edit
    }
  }, [_vm._v("\n      " + _vm._s(_vm.isDraftStatus ? _vm.$t('action.editDraft') : _vm.$t('action.edit')) + "\n    ")]) : _vm._e(), _vm._v(" "), _vm.canEditOrder && _vm.isDraftStatus ? _c('button', {
    staticClass: "tw-btn tw-btn-fill-primary ml-4",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.isSendEnable ? _vm.sendDraft() : _vm.confirmEdit();
      }
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('action.sendDraft')) + "\n    ")]) : _vm._e()], 1), _vm._v(" "), _c('Loading', {
    attrs: {
      "value": _vm.loading.order
    }
  }), _vm._v(" "), ((_vm$orderResponse2 = _vm.orderResponse) === null || _vm$orderResponse2 === void 0 ? void 0 : _vm$orderResponse2.status) === 12 // 承認待ち
  ? _c('AlertArea', {
    attrs: {
      "message": _vm.isDigitalGift ? _vm.$t('orders.id.requireAapprovedOrderWowLink', [(_vm$orderResponse$ass = (_vm$orderResponse3 = _vm.orderResponse) === null || _vm$orderResponse3 === void 0 ? void 0 : (_vm$orderResponse3$as = _vm$orderResponse3.assigned_to) === null || _vm$orderResponse3$as === void 0 ? void 0 : _vm$orderResponse3$as.name) !== null && _vm$orderResponse$ass !== void 0 ? _vm$orderResponse$ass : _vm.$t('authorizer')]) : _vm.$t('orders.id.requireAapprovedOrder', [(_vm$orderResponse$ass2 = (_vm$orderResponse4 = _vm.orderResponse) === null || _vm$orderResponse4 === void 0 ? void 0 : (_vm$orderResponse4$as = _vm$orderResponse4.assigned_to) === null || _vm$orderResponse4$as === void 0 ? void 0 : _vm$orderResponse4$as.name) !== null && _vm$orderResponse$ass2 !== void 0 ? _vm$orderResponse$ass2 : _vm.$t('authorizer')])
    }
  }, [((_vm$orderResponse5 = _vm.orderResponse) === null || _vm$orderResponse5 === void 0 ? void 0 : (_vm$orderResponse5$as = _vm$orderResponse5.assigned_to) === null || _vm$orderResponse5$as === void 0 ? void 0 : _vm$orderResponse5$as.email) === ((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.email) // 自分がアサインされてるオーダー
  ? _c('div', {
    staticClass: "tw-flex max-sm:tw-flex-col tw-gap-3 tw-shrink-0"
  }, [_c('button', {
    staticClass: "tw-btn tw-btn-small tw-btn-outline-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.rejectOrder
    }
  }, [_c('mdi-close', {
    staticClass: "tw-size-5 -tw-ml-1"
  }), _vm._v("\n        " + _vm._s(_vm.$t('orders.id.reject')) + "\n      ")], 1), _vm._v(" "), _c('FloatingTooltip', {
    attrs: {
      "top": "",
      "disabled": _vm.canApproveOrder,
      "max-width": "400px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_c('button', {
          staticClass: "tw-btn tw-btn-small tw-btn-fill-primary",
          attrs: {
            "type": "button",
            "disabled": !_vm.canApproveOrder
          },
          on: {
            "click": _vm.approveOrder
          }
        }, [_c('mdi-check', {
          staticClass: "tw-size-5 -tw-ml-1"
        }), _vm._v("\n              " + _vm._s(_vm.$t('orders.id.approve')) + "\n            ")], 1)])];
      }
    }], null, false, 1880232634)
  }, [_vm._v("\n        " + _vm._s(_vm.$t('warning.nonApprovableOrder')) + "\n      ")])], 1) : _vm._e()]) : _vm._e(), _vm._v(" "), ((_vm$orderResponse6 = _vm.orderResponse) === null || _vm$orderResponse6 === void 0 ? void 0 : _vm$orderResponse6.status) === 10 &&
  // 差し戻し
  ((_vm$orderResponse7 = _vm.orderResponse) === null || _vm$orderResponse7 === void 0 ? void 0 : (_vm$orderResponse7$cr = _vm$orderResponse7.created_by) === null || _vm$orderResponse7$cr === void 0 ? void 0 : _vm$orderResponse7$cr.email) === ((_vm$user2 = _vm.user) === null || _vm$user2 === void 0 ? void 0 : _vm$user2.email) // 自分が作成したオーダー
  ? _c('AlertArea', {
    attrs: {
      "message": _vm.$t('orders.id.requireReAssign')
    }
  }, [_c('FloatingTooltip', {
    attrs: {
      "left": "",
      "disabled": _vm.canApproveOrder,
      "max-width": "400px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('div', _vm._g(_vm._b({
          staticClass: "tw-shrink-0"
        }, 'div', attrs, false), on), [_c('button', {
          staticClass: "tw-btn tw-btn-small tw-btn-fill-primary",
          attrs: {
            "type": "button",
            "disabled": !_vm.canApproveOrder
          },
          on: {
            "click": function click($event) {
              _vm.dialog.reAssign = true;
            }
          }
        }, [_vm._v("\n            " + _vm._s(_vm.$t('orders.id.reAssign')) + "\n          ")])])];
      }
    }], null, false, 3712432610)
  }, [_vm._v(" "), _c('span', {
    staticClass: "text-body-2"
  }, [_vm._v(_vm._s(_vm.$t('orders.id.reAssignNote')))])])], 1) : _vm._e(), _vm._v(" "), _c('ContentArea', [_c('div', {
    staticClass: "d-flex align-center",
    staticStyle: {
      "height": "40px"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t('orderDescription')))])]), _vm._v(" "), _c('VerticalTable', {
    staticClass: "mt-4",
    attrs: {
      "headers": [{
        text: _vm.$t('createBy'),
        getter: function getter(val) {
          return _vm.$fullName(val.created_by);
        },
        half: true
      }, {
        text: _vm.$t('createAt'),
        getter: function getter(val) {
          return _vm.$date(val.created_at);
        },
        half: true
      }, {
        text: _vm.$t('authorizer'),
        value: 'authorizer',
        getter: function getter(val) {
          var _val$authorizer$name, _val$authorizer, _val$assigned_to;
          return (_val$authorizer$name = (_val$authorizer = val.authorizer) === null || _val$authorizer === void 0 ? void 0 : _val$authorizer.name) !== null && _val$authorizer$name !== void 0 ? _val$authorizer$name : (_val$assigned_to = val.assigned_to) === null || _val$assigned_to === void 0 ? void 0 : _val$assigned_to.name;
        },
        disabled: !((_vm$orderResponse8 = _vm.orderResponse) !== null && _vm$orderResponse8 !== void 0 && _vm$orderResponse8.assigned_to) && !((_vm$orderResponse9 = _vm.orderResponse) !== null && _vm$orderResponse9 !== void 0 && _vm$orderResponse9.authorizer)
      }],
      "item": _vm.orderResponse,
      "dummy": _vm.loading.order
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "d-flex mt-6"
  }, [_c('SectionSubTitle', [_vm._v(_vm._s(_vm.$t('contact')))])], 1), _vm._v(" "), _c('VerticalTable', {
    attrs: {
      "headers": [{
        text: _vm.$t('name'),
        value: 'contact.name',
        half: true
      }, {
        text: _vm.$t('salutation'),
        value: 'contact.title',
        half: true
      }, {
        text: _vm.$t('accountName'),
        value: 'contact.account.name'
      }, {
        text: _vm.$t('department'),
        value: 'contact.department',
        half: true
      }, {
        text: _vm.$t('jobTitle'),
        value: 'contact.job_title',
        half: true
      }, {
        text: _vm.$t('destinationAddress'),
        value: 'contact.address',
        disabled: _vm.isDigitalGift
      }, {
        text: _vm.$t('owner'),
        value: 'contact.owner',
        getter: function getter(val) {
          var _val$contact;
          return _vm.$fullName((_val$contact = val.contact) === null || _val$contact === void 0 ? void 0 : _val$contact.owner);
        }
      }],
      "item": _vm.orderResponse,
      "dummy": _vm.loading.order
    },
    scopedSlots: _vm._u([{
      key: "item.contact.name",
      fn: function fn(_ref3) {
        var contact = _ref3.contact;
        return [contact ? _c('LinkText', {
          attrs: {
            "to": contact !== null && contact !== void 0 && contact.id ? "/contact/".concat(contact === null || contact === void 0 ? void 0 : contact.id) : null
          },
          domProps: {
            "textContent": _vm._s(_vm.$fullName(contact))
          }
        }) : _vm._e()];
      }
    }, {
      key: "item.contact.account.name",
      fn: function fn(_ref4) {
        var _contact$account;
        var contact = _ref4.contact;
        return [contact !== null && contact !== void 0 && contact.account ? _c('LinkText', {
          attrs: {
            "to": contact !== null && contact !== void 0 && (_contact$account = contact.account) !== null && _contact$account !== void 0 && _contact$account.id ? "/accounts/".concat(contact.account.id) : null
          }
        }, [_vm._v("\n          " + _vm._s(contact.account.name) + "\n        ")]) : contact !== null && contact !== void 0 && contact.crm_company_name ? [_vm._v("\n          " + _vm._s(contact.crm_company_name) + "\n        ")] : _c('div', {
          staticClass: "text-body-2 disabled-text--text"
        }, [_vm._v("\n          " + _vm._s(_vm.$t('notSet')) + "\n        ")])];
      }
    }, {
      key: "item.contact.address",
      fn: function fn(_ref5) {
        var _vm$orderResponse10;
        var shipping_address_snapshot = _ref5.shipping_address_snapshot,
          status = _ref5.status,
          order_type = _ref5.order_type;
        return [order_type === 'address_confirmation' && status === 1 ? _c('div', {
          staticClass: "text-body-1 d-inline-flex mb-1"
        }, [_c(VIcon, {
          staticClass: "mr-1",
          attrs: {
            "color": "disabled-text"
          }
        }, [_vm._v("mdi-check-circle")]), _vm._v(" "), _c('span', {
          staticClass: "disabled-text--text"
        }, [_vm._v("\n            " + _vm._s(_vm.$t('orders.id.enteringAddressAgency')) + "\n          ")])], 1) : order_type === 'address_confirmation' && [3, 4, 6, 7, 8, 9].includes((_vm$orderResponse10 = _vm.orderResponse) === null || _vm$orderResponse10 === void 0 ? void 0 : _vm$orderResponse10.status) ? _c('div', {
          staticClass: "text-body-1 d-inline-flex mb-1"
        }, [_c(VIcon, {
          staticClass: "mr-1",
          attrs: {
            "color": "success"
          }
        }, [_vm._v("mdi-check-circle")]), _vm._v(" "), _c('span', {
          staticClass: "success--text"
        }, [_vm._v("\n            " + _vm._s(_vm.$t('orders.appliedAddressConfirmAgencyService')) + "\n          ")])], 1) : _vm._e(), _vm._v(" "), !_vm.$isEmptyAddress(shipping_address_snapshot) ? _c('AddressLabel', {
          attrs: {
            "detail": "",
            "address": shipping_address_snapshot
          }
        }) : _c('div', {
          staticClass: "d-flex align-center"
        }, [_c(VIcon, {
          staticClass: "mr-2",
          attrs: {
            "size": "24",
            "color": "warning"
          }
        }, [_vm._v("mdi-alert")]), _vm._v(" "), _c('span', {
          staticClass: "text-body-1 warning--text"
        }, [_vm._v(_vm._s(_vm.$t('notSet')))])], 1), _vm._v(" "), _c('AddressAlert', {
          staticClass: "mt-2",
          attrs: {
            "address": shipping_address_snapshot,
            "at-order": "",
            "fixed-order": status >= 1
          }
        })];
      }
    }])
  }), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "mt-6"
  }, [_vm._v(_vm._s(_vm.$t('delivery')))]), _vm._v(" "), _c('VerticalTable', {
    staticClass: "mt-4",
    attrs: {
      "headers": [{
        text: _vm.$t('touch'),
        value: 'touch'
      }, {
        text: _vm.$t('cost'),
        value: 'cost',
        dummy: _vm.loading.order
      }, {
        text: _vm.isDigitalGift ? _vm.$t('issueDate') : _vm.$t('desiredArrivalDate'),
        value: 'shippingDate',
        dummy: _vm.loading.order
      }, {
        text: _vm.$t('printedText'),
        value: 'message',
        disabled: !((_vm$productCustoms$te = _vm.productCustoms.texts) !== null && _vm$productCustoms$te !== void 0 && _vm$productCustoms$te.length)
      }, {
        text: _vm.$t('qrcode'),
        value: 'qrcode',
        disabled: !_vm.productCustoms.url && !_vm.orderResponse.touched_at,
        info: _vm.$t('orders.id.qrcodeAnnotation')
      }, {
        text: _vm.hasShippableProduct ? _vm.$t('qrcode') : _vm.$t('wowLink'),
        value: 'digitalGift',
        disabled: !_vm.giftPagePreviewUrl
      }, {
        text: _vm.$t('giftPage'),
        value: 'giftPage',
        disabled: !_vm.lineItem.lp || _vm.loading.order
      }],
      "item": _vm.orderResponse
    },
    scopedSlots: _vm._u([{
      key: "item.touch",
      fn: function fn() {
        return [_c('TouchSummary', {
          staticClass: "py-2",
          attrs: {
            "item": _vm.lineItem,
            "link": _vm.lineItem.touch != null,
            "dummy": _vm.loading.order
          }
        })];
      },
      proxy: true
    }, {
      key: "item.cost",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-body-1"
        }, [_vm._v(_vm._s(_vm.$price(_vm.orderResponse.price)))])];
      },
      proxy: true
    }, {
      key: "item.shippingDate",
      fn: function fn() {
        return [!_vm.isDigitalGift || _vm.orderResponse.status === 9 ? [_vm._v("\n          " + _vm._s(_vm.$date(_vm.orderResponse.shipping_date)) + "\n        ")] : _vm.orderResponse.status === 2 || _vm.orderResponse.status === 4 ? _c('div', {
          staticClass: "text-body-2 disabled-text--text"
        }, [_vm._v("\n          " + _vm._s(_vm.$t('orders.id.wowLinkCanceled')) + "\n        ")]) : _c('div', {
          staticClass: "text-body-2 disabled-text--text"
        }, [_vm._v("\n          " + _vm._s(_vm.$t('orders.id.wowLinkNotPublished')) + "\n        ")])];
      },
      proxy: true
    }, {
      key: "item.message",
      fn: function fn() {
        var _vm$orderResponse$con;
        return [_c('TouchTextViewer', {
          staticClass: "py-2",
          attrs: {
            "value": _vm.orderCustomTexts,
            "svg": _vm.productTemplate,
            "check-placeholder": "",
            "shipping-targets": [{
              contact: _objectSpread({}, _vm.isContactSnapshot(_vm.orderResponse.contact_snapshot) ? _objectSpread(_objectSpread({}, _vm.orderResponse.contact_snapshot), {}, {
                // Note: ownerは現状contact_snapshotに含まれないのでcontact.ownerの情報を使う
                owner: (_vm$orderResponse$con = _vm.orderResponse.contact_snapshot.owner) !== null && _vm$orderResponse$con !== void 0 ? _vm$orderResponse$con : _vm.orderResponse.contact.owner
              }) : _vm.orderResponse.contact),
              shippingAddress: _vm.orderResponse.shipping_address_snapshot
            }],
            "qr-url": _vm.lineItem.url,
            "logo": _vm.company.image,
            "customs-texts": _vm.productCustoms.texts,
            "gift-url": _vm.giftPagePreviewUrl,
            "orderer": _vm.orderResponse.created_by
          },
          on: {
            "update:error": function updateError($event) {
              _vm.hasTextError = $event;
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "item.qrcode",
      fn: function fn() {
        return [_c('QrcodeDetail', {
          staticClass: "py-2",
          attrs: {
            "url": _vm.lineItem.url,
            "opened-at": _vm.orderResponse.touched_at
          }
        })];
      },
      proxy: true
    }, {
      key: "item.digitalGift",
      fn: function fn() {
        var _vm$lineItem$lp, _vm$lineItem$lp2, _vm$lineItem$lp2$egif, _vm$lineItem$lp3, _vm$lineItem$lp3$egif;
        return [_vm.hasShippableProduct ? _c('div', [[0, 1, 10, 11, 12].includes(_vm.orderResponse.status) ? _c('div', {
          staticClass: "text-body-2 disabled-text--text"
        }, [_vm._v("\n            " + _vm._s(_vm.$t('orders.id.qrcodeNotPublished')) + "\n          ")]) : [2, 4].includes(_vm.orderResponse.status) ? _c('div', {
          staticClass: "text-body-2 disabled-text--text"
        }, [_vm._v("\n            " + _vm._s(_vm.$t('orders.id.qrcodeCanceled')) + "\n          ")]) : _c('QrcodeDetail', {
          staticClass: "py-2",
          attrs: {
            "url": _vm.giftPagePreviewUrl,
            "opened-at": _vm.lineItem.lp.opened_at
          },
          scopedSlots: _vm._u([{
            key: "footer",
            fn: function fn() {
              var _vm$lineItem$lp$egift, _vm$lineItem$lp$egift2;
              return [(_vm$lineItem$lp$egift = _vm.lineItem.lp.egift) !== null && _vm$lineItem$lp$egift !== void 0 && _vm$lineItem$lp$egift.donated_at ? _c('div', {
                staticClass: "text-body-2 d-flex align-center primary--text"
              }, [_c('IconsTreeCircle', {
                staticClass: "mr-1",
                attrs: {
                  "width": "24",
                  "height": "24"
                }
              }), _vm._v("\n                " + _vm._s(_vm.$t('orders.id.giftDonated', [_vm.$datetime((_vm$lineItem$lp$egift2 = _vm.lineItem.lp.egift) === null || _vm$lineItem$lp$egift2 === void 0 ? void 0 : _vm$lineItem$lp$egift2.donated_at)])) + "\n              ")], 1) : _vm._e()];
            },
            proxy: true
          }], null, false, 522812277)
        })], 1) : _c('div', [[0, 1, 10, 11, 12].includes(_vm.orderResponse.status) ? _c('div', {
          staticClass: "text-body-2 disabled-text--text"
        }, [_vm._v("\n            " + _vm._s(_vm.$t('orders.id.wowLinkNotPublished')) + "\n          ")]) : [2, 4].includes(_vm.orderResponse.status) ? _c('div', {
          staticClass: "text-body-2 disabled-text--text"
        }, [_vm._v("\n            " + _vm._s(_vm.$t('orders.id.wowLinkCanceled')) + "\n          ")]) : _c('div', [_c('Input', {
          attrs: {
            "type": "text",
            "readonly": "",
            "value": _vm.giftPagePreviewUrl
          },
          scopedSlots: _vm._u([{
            key: "append",
            fn: function fn() {
              return [_c('div', {
                staticClass: "d-flex align-center"
              }, [_c(VDivider, {
                staticClass: "mx-2",
                attrs: {
                  "vertical": ""
                }
              }), _vm._v(" "), _c('TextButton', {
                staticClass: "mr-1",
                attrs: {
                  "value": _vm.$t('action.copy'),
                  "icon": "mdi-content-copy"
                },
                on: {
                  "click": _vm.onClickCopy
                }
              })], 1)];
            },
            proxy: true
          }])
        }), _vm._v(" "), (_vm$lineItem$lp = _vm.lineItem.lp) !== null && _vm$lineItem$lp !== void 0 && _vm$lineItem$lp.opened_at ? _c('div', {
          staticClass: "text-body-2 pt-3 d-flex align-center primary--text"
        }, [_c(VIcon, {
          staticClass: "mr-1",
          attrs: {
            "size": "24",
            "color": "primary"
          }
        }, [_vm._v("\n                mdi-check-circle\n              ")]), _vm._v("\n              " + _vm._s(_vm.$t('orders.id.wowLinkRead', [_vm.$datetime(_vm.lineItem.lp.opened_at)])) + "\n            ")], 1) : _c('div', {
          staticClass: "text-body-2 pt-3 d-flex align-center disabled-text--text"
        }, [_c(VIcon, {
          staticClass: "mr-1",
          attrs: {
            "size": "24",
            "color": "disabled-text"
          }
        }, [_vm._v("\n                mdi-check-circle\n              ")]), _vm._v("\n              " + _vm._s(_vm.$t('orders.id.wowLinkNotRead')) + "\n            ")], 1), _vm._v(" "), (_vm$lineItem$lp2 = _vm.lineItem.lp) !== null && _vm$lineItem$lp2 !== void 0 && (_vm$lineItem$lp2$egif = _vm$lineItem$lp2.egift) !== null && _vm$lineItem$lp2$egif !== void 0 && _vm$lineItem$lp2$egif.donated_at ? _c('div', {
          staticClass: "text-body-2 d-flex align-center primary--text"
        }, [_c('IconsTreeCircle', {
          staticClass: "mr-1",
          attrs: {
            "width": "24",
            "height": "24"
          }
        }), _vm._v("\n              " + _vm._s(_vm.$t('orders.id.giftDonated', [_vm.$datetime((_vm$lineItem$lp3 = _vm.lineItem.lp) === null || _vm$lineItem$lp3 === void 0 ? void 0 : (_vm$lineItem$lp3$egif = _vm$lineItem$lp3.egift) === null || _vm$lineItem$lp3$egif === void 0 ? void 0 : _vm$lineItem$lp3$egif.donated_at)])) + "\n            ")], 1) : _vm._e()], 1)])];
      },
      proxy: true
    }, {
      key: "item.giftPage",
      fn: function fn() {
        var _vm$orderResponse$con2, _ref6, _vm$lineItem$shopify_, _vm$lineItem$shopify_2, _vm$lineItem$shopify_3, _vm$lineItem$shopify_4, _vm$lineItem$shopify_5, _vm$lineItem$shopify_6, _vm$lineItem$shopify_7;
        return [_c('GiftPageEditor', {
          staticClass: "py-2",
          attrs: {
            "value": _vm.lineItem.lp,
            "logo": _vm.company.image,
            "shipping-targets": [{
              contact: _objectSpread({}, _vm.isContactSnapshot(_vm.orderResponse.contact_snapshot) ? _objectSpread(_objectSpread({}, _vm.orderResponse.contact_snapshot), {}, {
                // Note: ownerは現状contact_snapshotに含まれないのでcontact.ownerの情報を使う
                owner: (_vm$orderResponse$con2 = _vm.orderResponse.contact_snapshot.owner) !== null && _vm$orderResponse$con2 !== void 0 ? _vm$orderResponse$con2 : _vm.orderResponse.contact.owner
              }) : _vm.orderResponse.contact),
              shippingAddress: _vm.orderResponse.shipping_address_snapshot
            }],
            "product-title": (_ref6 = (_vm$lineItem$shopify_ = (_vm$lineItem$shopify_2 = _vm.lineItem.shopify_product) === null || _vm$lineItem$shopify_2 === void 0 ? void 0 : _vm$lineItem$shopify_2.display_gift_name) !== null && _vm$lineItem$shopify_ !== void 0 ? _vm$lineItem$shopify_ : (_vm$lineItem$shopify_3 = _vm.lineItem.shopify_product) === null || _vm$lineItem$shopify_3 === void 0 ? void 0 : _vm$lineItem$shopify_3.product_title) !== null && _ref6 !== void 0 ? _ref6 : '',
            "product-image": (_vm$lineItem$shopify_4 = (_vm$lineItem$shopify_5 = _vm.lineItem.shopify_product) === null || _vm$lineItem$shopify_5 === void 0 ? void 0 : _vm$lineItem$shopify_5.display_gift_image) !== null && _vm$lineItem$shopify_4 !== void 0 ? _vm$lineItem$shopify_4 : (_vm$lineItem$shopify_6 = _vm.lineItem.shopify_product) === null || _vm$lineItem$shopify_6 === void 0 ? void 0 : (_vm$lineItem$shopify_7 = _vm$lineItem$shopify_6.images) === null || _vm$lineItem$shopify_7 === void 0 ? void 0 : _vm$lineItem$shopify_7[0],
            "orderer": _vm.orderResponse.created_by
          },
          on: {
            "update:error": function updateError($event) {
              _vm.hasGiftError = $event;
            }
          }
        })];
      },
      proxy: true
    }])
  })], 1), _vm._v(" "), _c('ContentArea', [_c('h6', {
    staticClass: "mb-6"
  }, [_vm._v(_vm._s(_vm.$t('orders.id.comment')))]), _vm._v(" "), _vm.loading.order ? _c('div', {
    staticClass: "d-flex align-center justify-center mb-4"
  }, [_c(VProgressCircular, {
    attrs: {
      "color": "primary",
      "indeterminate": ""
    }
  })], 1) : ((_vm$orderResponse11 = _vm.orderResponse) === null || _vm$orderResponse11 === void 0 ? void 0 : (_vm$orderResponse11$c = _vm$orderResponse11.comments) === null || _vm$orderResponse11$c === void 0 ? void 0 : _vm$orderResponse11$c.length) > 0 ? _vm._l((_vm$orderResponse12 = _vm.orderResponse) === null || _vm$orderResponse12 === void 0 ? void 0 : _vm$orderResponse12.comments, function (comment) {
    return _c('div', {
      key: comment.id
    }, [(_vm.ownComment = _vm.isOwnComment(comment)) ? void 0 : _vm._e(), _vm._v(" "), _c('FrameText', {
      class: {
        'ml-6': _vm.ownComment,
        'mr-6': !_vm.ownComment,
        ownComment: _vm.ownComment
      },
      attrs: {
        "value": comment.text,
        "pre": ""
      }
    }), _vm._v(" "), _c('div', {
      staticClass: "secondary-text--text text-caption d-flex mb-6 mt-2",
      class: {
        'justify-end': _vm.ownComment
      }
    }, [_vm._v("\n          " + _vm._s(comment.created_by.name) + "\n          "), _c('FloatingTooltip', {
      attrs: {
        "top": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref7) {
          var on = _ref7.on,
            attrs = _ref7.attrs;
          return [_c('span', _vm._g(_vm._b({
            staticClass: "ml-2"
          }, 'span', attrs, false), on), [_vm._v("\n                " + _vm._s(_vm.$fromNow(comment.updated_at)) + "\n              ")])];
        }
      }], null, true)
    }, [_vm._v(" "), _c('span', {
      staticClass: "text-caption"
    }, [_vm._v("\n              " + _vm._s(_vm.$datetime(comment.updated_at)) + "\n            ")])]), _vm._v(" "), _vm.ownComment ? _c(VDivider, {
      staticClass: "mx-2 my-1",
      attrs: {
        "vertical": ""
      }
    }) : _vm._e(), _vm._v(" "), _vm.ownComment ? _c('TextButton', {
      attrs: {
        "x-small": "",
        "color": "error",
        "value": _vm.$t('orders.id.deleteComment')
      },
      on: {
        "click": function click($event) {
          return _vm.deleteComment(comment);
        }
      }
    }) : _vm._e()], 1)], 2);
  }) : _c('div', {
    staticClass: "text-body-2 mb-6"
  }, [_vm._v("\n      " + _vm._s(_vm.$t('orders.id.noComments')) + "\n    ")]), _vm._v(" "), _c(VDivider, {
    staticClass: "mx-n6",
    staticStyle: {
      "max-width": "initial"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "d-flex align-end mt-6"
  }, [_c('Input', {
    attrs: {
      "type": "multiLine",
      "placeholder": _vm.$t('placeholder.comment')
    },
    model: {
      value: _vm.commentText,
      callback: function callback($$v) {
        _vm.commentText = $$v;
      },
      expression: "commentText"
    }
  }), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-small tw-btn-outline-primary ml-4",
    attrs: {
      "type": "button",
      "disabled": _vm.loading.order
    },
    on: {
      "click": function click($event) {
        return _vm.postComment();
      }
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('action.send')) + "\n      ")])], 1)], 2), _vm._v(" "), _c('CustomDialog', {
    attrs: {
      "form": "",
      "loading": _vm.processing,
      "title": _vm.$t('orders.reAssignConfirm'),
      "negative": _vm.$t('action.cancel'),
      "positive": _vm.$t('action.ok'),
      "disabled-positive": _vm.assignedTo === null
    },
    on: {
      "click:positive": _vm.reAssignOrder,
      "close": function close($event) {
        _vm.assignedTo = null;
        _vm.reAssignComment = '';
      }
    },
    model: {
      value: _vm.dialog.reAssign,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog, "reAssign", $$v);
      },
      expression: "dialog.reAssign"
    }
  }, [_c('Input', {
    staticClass: "mt-4",
    attrs: {
      "label": _vm.$t('orders.commentPlaceHolder')
    },
    model: {
      value: _vm.reAssignComment,
      callback: function callback($$v) {
        _vm.reAssignComment = $$v;
      },
      expression: "reAssignComment"
    }
  }), _vm._v(" "), _c('Input', {
    staticClass: "mt-4",
    attrs: {
      "type": "autocomplete",
      "label": _vm.$t('orders.reAssignConfirmLabel'),
      "items": _vm.teamManagers,
      "item-text": "name",
      "item-value": "email"
    },
    model: {
      value: _vm.assignedTo,
      callback: function callback($$v) {
        _vm.assignedTo = $$v;
      },
      expression: "assignedTo"
    }
  })], 1), _vm._v(" "), !_vm.loading.order ? _c('div', {
    staticClass: "tw-flex tw-justify-center tw-mt-6"
  }, [(_vm$orderResponse13 = _vm.orderResponse) !== null && _vm$orderResponse13 !== void 0 && _vm$orderResponse13.cancelable || [0, 1, 3, 6, 10, 11, 12].includes((_vm$orderResponse14 = _vm.orderResponse) === null || _vm$orderResponse14 === void 0 ? void 0 : _vm$orderResponse14.status) ? _c('FloatingTooltip', {
    attrs: {
      "top": "",
      "disabled": (_vm$orderResponse15 = _vm.orderResponse) === null || _vm$orderResponse15 === void 0 ? void 0 : _vm$orderResponse15.cancelable,
      "max-width": "400px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref8) {
        var _vm$orderResponse16;
        var on = _ref8.on,
          attrs = _ref8.attrs;
        return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_c('button', {
          staticClass: "tw-btn tw-btn-outline-error",
          attrs: {
            "type": "button",
            "disabled": !((_vm$orderResponse16 = _vm.orderResponse) !== null && _vm$orderResponse16 !== void 0 && _vm$orderResponse16.cancelable)
          },
          on: {
            "click": _vm.cancelOrder
          }
        }, [_vm._v("\n            " + _vm._s(_vm.isDraftStatus ? _vm.$t('action.deletionDraft') : _vm.$t('action.cancelDelivery')) + "\n          ")])])];
      }
    }], null, false, 141141782)
  }, [_vm._v("\n      " + _vm._s(_vm.$t('info.cancelDelivery')) + "\n    ")]) : _vm._e()], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };