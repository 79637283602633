import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "d-flex"
  }, [_c('Qrcode', {
    staticStyle: {
      "width": "100px",
      "height": "100px"
    },
    attrs: {
      "value": _setup.props.url
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "ml-5 align-self-center",
    staticStyle: {
      "flex-shrink": "2"
    }
  }, [_c('div', {
    staticClass: "text-body-1"
  }, [_vm._v("\n      " + _vm._s(_vm.$t('orders.id.qrcodeLink') + (_setup.isGiftUrl ? "\uFF08".concat(_vm.$t('giftPage'), "\uFF09") : "")) + "\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v("\n      " + _vm._s(_setup.props.url) + "\n      "), _setup.hasUrlTestParam ? _c('FloatingTooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c(VIcon, _vm._g({
          staticClass: "ml-1",
          attrs: {
            "small": ""
          }
        }, on), [_vm._v("mdi-information")])];
      }
    }], null, false, 1960462187)
  }, [_vm._v(" "), _c('pre', {
    staticClass: "text-body-2"
  }, [_vm._v(_vm._s(_vm.$t('orders.id.giftUrlAnnotation')))])]) : _vm._e()], 1), _vm._v(" "), _setup.props.openedAt ? _c('div', {
    staticClass: "text-body-2 pt-3 d-flex align-center primary--text"
  }, [_c(VIcon, {
    staticClass: "mr-1",
    attrs: {
      "size": "24",
      "color": "primary"
    }
  }, [_vm._v("mdi-check-circle")]), _vm._v("\n      " + _vm._s(_vm.$t('orders.id.qrcodeRead', [_vm.$datetime(_setup.props.openedAt)])) + "\n    ")], 1) : _c('div', {
    staticClass: "text-body-2 pt-3 d-flex align-center disabled-text--text"
  }, [_c(VIcon, {
    staticClass: "mr-1",
    attrs: {
      "size": "24",
      "color": "disabled-text"
    }
  }, [_vm._v("\n        mdi-check-circle\n      ")]), _vm._v("\n      " + _vm._s(_vm.$t('orders.id.qrcodeNotRead')) + "\n    ")], 1), _vm._v(" "), _vm.$slots.footer ? _c('div', {
    staticClass: "pt-3"
  }, [_vm._t("footer")], 2) : _vm._e()])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };