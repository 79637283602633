/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./QrcodeDetail.vue?vue&type=template&id=3e009bc8"
import script from "./QrcodeDetail.vue?vue&type=script&lang=ts&setup=true"
export * from "./QrcodeDetail.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Qrcode: require('/code/src/components/Qrcode.vue').default,FloatingTooltip: require('/code/src/components/tooltip/FloatingTooltip.vue').default})
